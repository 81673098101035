import React, { useEffect } from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Landing from "../components/energy-retrofit/landing"
import Steps from "../components/energy-retrofit/steps"
import Core from "../components/energy-retrofit/core"
import CaseStudy from "../components/case-study"

const EnergyRetrofits = ({ data }) => {
  useEffect(() => {}, [])

  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata?.es?.header}
      footerLang={data?.langQuery?.siteMetadata?.es?.footer}
      langType="es"
    >
      <SEO
        title="Energy Retrofit"
        description="EEE performs Energy Retrofit Projects timely, gloabaly, and remotely."
      />
      <Landing
        fluid={data?.headerImage?.childImageSharp?.fluid}
        lang={data?.langQuery?.siteMetadata?.es?.retrofit?.landing}
        langType="es"
      />
      <div id="more-info">
        <Core lang={data?.langQuery?.siteMetadata?.es?.retrofit?.core} />
        <Steps lang={data?.langQuery?.siteMetadata?.es?.retrofit?.process} />
        <CaseStudy data={data} />
      </div>
    </Layout>
  )
}

export default EnergyRetrofits

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "energy-retrofit.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    langQuery: site {
      siteMetadata {
        es {
          header {
            commissioning
            modeling
            retrofits
            cfd
            projects
            contact
          }
          footer {
            contact
            navItems
          }
          retrofit {
            landing {
              title
              body
            }
            core {
              title
              body
            }
            process {
              title
              steps
            }
          }
        }
      }
    }
    allContentfulFeaturedProject(
      filter: {
        service: { name: { eq: "Energy Retrofits" }, node_locale: { eq: "es" } }
      }
    ) {
      edges {
        node {
          id
          name
          summary {
            summary
          }
          previewImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
  }
`
